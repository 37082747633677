<template>
  <v-dialog
    v-model="isAddNewSlideShow"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title>
        {{ $t('add_slideshows') }}
      </v-card-title>
      <v-form
        ref="formAddSlideShow"
        @submit.prevent="addSlideShow"
      >
        <v-card-text>
          <v-text-field
            v-model="slideshop_name"
            dense
            outlined
            :rules="[required]"
            :label="$t('name')"
          >
          </v-text-field>
          <v-text-field
            v-model="slideshop_link"
            dense
            :rules="[required]"
            outlined
            :label="$t('destination_link')"
          >
          </v-text-field>

          <v-select
            v-model.trim="slideshop_open_link"
            :items="openLinkData"
            :item-text="$i18n.locale"
            dense
            :rules="[required]"
            :label="$t('open_link')"
            outlined
            item-value="value"
          ></v-select>
          <div class="d-flex">
            <span class="font-weight-semibold pt-2">
              {{ $t("picture") }} 1200X445
            </span>
            <v-tooltip
              color="#212121"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  color="primary"
                  @click="$refs.selectImage1.click()"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiCloudUploadOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("upload_pictures") }}</span>
            </v-tooltip>
            <input
              ref="selectImage1"
              type="file"
              accept="image/*"
              style="display: none"
              @change="previewFiles1"
            />
          </div>
          <v-img
            height="200px"
            :src="blob_image1"
          ></v-img>
          <div class="d-flex">
            <span class="font-weight-semibold pt-2">
              {{ $t("picture") }} 375X360
            </span>
            <v-tooltip
              color="#212121"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  color="primary"
                  @click="$refs.selectImage2.click()"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiCloudUploadOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("upload_pictures") }}</span>
            </v-tooltip>
            <input
              ref="selectImage2"
              type="file"
              accept="image/*"
              style="display: none"
              @change="previewFiles2"
            />
          </div>
          <v-img
            height="200px"
            :src="blob_image2"
          ></v-img>
          <v-select
            v-model.trim="slideshop_status_id"
            :items="statusData"
            class="mt-5"
            :item-text="$i18n.locale"
            dense
            :rules="[required]"
            :label="$t('open_link')"
            outlined
            item-value="value"
          ></v-select>
          <v-datetime-picker
            v-if="slideshop_status_id == 3"
            v-model="slideshop_start"
            :rules="[required]"
            :ok-text="$t('save')"
            :clear-text="$t('cancel')"
            :text-field-props="{
              dense: true,
              outlined: true,
              flat:true
            }"
            :date-picker-props="{
              locale: $i18n.locale,
              elevation:0
            }"
            :time-picker-props="{
              locale: $i18n.locale,
              elevation:0,
              format:'24hr'
            }"
            :label="$t('start_showing')"
          >
            <template slot="dateIcon">
              <v-icon>{{ icons.mdiCalendarToday }}</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>{{ icons.mdiClockOutline }}</v-icon>
            </template>
          </v-datetime-picker>
          <v-datetime-picker
            v-if="slideshop_status_id == 3"
            v-model="slideshop_end"
            :ok-text="$t('save')"
            :rules="[required]"
            :clear-text="$t('cancel')"
            :text-field-props="{
              dense: true,
              outlined: true,
            }"
            :date-picker-props="{
              locale: $i18n.locale,
            }"
            :time-picker-props="{
              locale: $i18n.locale,
              format:'24hr'
            }"
            :label="$t('end_of_show')"
          >
            <template slot="dateIcon">
              <v-icon>{{ icons.mdiCalendarToday }}</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>{{ icons.mdiClockOutline }}</v-icon>
            </template>
          </v-datetime-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            type="submit"
            :loading="loading"
            :disabled="loading"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:is-add-new-slide-show',false)"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCloudUploadOutline, mdiCalendarToday, mdiClockOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import slideManagement from '@/api/WebsiteManageMent/slideManagement'
import store from '@/store'
import { required } from '@/@core/utils/validation'

export default {
  model: {
    prop: 'isAddNewSlideShow',
    event: 'update:is-add-new-slide-show',
  },
  props: {
    isAddNewSlideShow: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const slideshop_name = ref('')
    const slideshop_link = ref('')
    const formAddSlideShow = ref(null)
    const slideshop_open_link = ref(1)
    const openLinkData = ref([
      { en: 'Same Page', th: 'หน้าเดิม', value: 1 },
      { en: 'New Page', th: 'หน้าใหม่', value: 2 },
    ])
    const statusData = ref([
      { en: 'Open', th: 'เปิด', value: 1 },
      { en: 'Close', th: 'ปิด', value: 2 },
      { en: 'Set time', th: 'ตั้งเวลา', value: 3 },
    ])

    const slideshop_status_id = ref(1)
    const slideshop_start = ref('')
    const slideshop_end = ref('')
    const blob_image1 = ref('')
    const blob_image2 = ref('')
    const slideshop_image = ref('')
    const slideshop_image_half = ref('')
    const loading = ref(false)

    const { slideManagementAdd } = slideManagement

    const addSlideShow = () => {
      const isInvalidForm = formAddSlideShow.value.validate()
      if (!isInvalidForm) return
      loading.value = true
      const formData = new FormData()
      formData.append('slideshop_page_id', props.id)
      formData.append('slideshop_image', slideshop_image.value)
      formData.append('slideshop_image_half', slideshop_image_half.value)
      formData.append('slideshop_name', slideshop_name.value)
      formData.append('slideshop_link', slideshop_link.value)
      formData.append('slideshop_open_link', slideshop_open_link.value)
      formData.append('slideshop_status_id', slideshop_status_id.value)
      formData.append('slideshop_start', slideshop_start.value)
      formData.append('slideshop_end', slideshop_end.value)
      slideManagementAdd(formData).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-add-new-slide-show', false)
        emit('onAdd')
        loading.value = false
      })
    }

    const previewFiles1 = event => {
      slideshop_image.value = event.target.files[event.target.files.length - 1]
      blob_image1.value = URL.createObjectURL(
        event.target.files[event.target.files.length - 1],
      )
    }

    const previewFiles2 = event => {
      slideshop_image_half.value = event.target.files[event.target.files.length - 1]
      blob_image2.value = URL.createObjectURL(
        event.target.files[event.target.files.length - 1],
      )
    }

    watch(() => props.isAddNewSlideShow, value => {
      if (value) {
        slideshop_name.value = ''
        slideshop_link.value = ''
        slideshop_open_link.value = 1
        slideshop_status_id.value = 1
        slideshop_start.value = ''
        slideshop_end.value = ''
        slideshop_image.value = ''
        slideshop_image_half.value = ''
        blob_image1.value = ''
        blob_image2.value = ''
      }
    })

    return {
      slideshop_name,
      slideshop_link,
      formAddSlideShow,
      openLinkData,
      slideshop_open_link,
      statusData,
      slideshop_status_id,
      slideshop_start,
      slideshop_end,
      blob_image1,
      blob_image2,
      addSlideShow,
      previewFiles2,
      previewFiles1,
      required,
      loading,
      icons: {
        mdiCloudUploadOutline, mdiCalendarToday, mdiClockOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
