var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.isAddNewSlideShow),callback:function ($$v) {_vm.isAddNewSlideShow=$$v},expression:"isAddNewSlideShow"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('add_slideshows'))+" ")]),_c('v-form',{ref:"formAddSlideShow",on:{"submit":function($event){$event.preventDefault();return _vm.addSlideShow.apply(null, arguments)}}},[_c('v-card-text',[_c('v-text-field',{attrs:{"dense":"","outlined":"","rules":[_vm.required],"label":_vm.$t('name')},model:{value:(_vm.slideshop_name),callback:function ($$v) {_vm.slideshop_name=$$v},expression:"slideshop_name"}}),_c('v-text-field',{attrs:{"dense":"","rules":[_vm.required],"outlined":"","label":_vm.$t('destination_link')},model:{value:(_vm.slideshop_link),callback:function ($$v) {_vm.slideshop_link=$$v},expression:"slideshop_link"}}),_c('v-select',{attrs:{"items":_vm.openLinkData,"item-text":_vm.$i18n.locale,"dense":"","rules":[_vm.required],"label":_vm.$t('open_link'),"outlined":"","item-value":"value"},model:{value:(_vm.slideshop_open_link),callback:function ($$v) {_vm.slideshop_open_link=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"slideshop_open_link"}}),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"font-weight-semibold pt-2"},[_vm._v(" "+_vm._s(_vm.$t("picture"))+" 1200X445 ")]),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$refs.selectImage1.click()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCloudUploadOutline))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("upload_pictures")))])]),_c('input',{ref:"selectImage1",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.previewFiles1}})],1),_c('v-img',{attrs:{"height":"200px","src":_vm.blob_image1}}),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"font-weight-semibold pt-2"},[_vm._v(" "+_vm._s(_vm.$t("picture"))+" 375X360 ")]),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$refs.selectImage2.click()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCloudUploadOutline))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("upload_pictures")))])]),_c('input',{ref:"selectImage2",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.previewFiles2}})],1),_c('v-img',{attrs:{"height":"200px","src":_vm.blob_image2}}),_c('v-select',{staticClass:"mt-5",attrs:{"items":_vm.statusData,"item-text":_vm.$i18n.locale,"dense":"","rules":[_vm.required],"label":_vm.$t('open_link'),"outlined":"","item-value":"value"},model:{value:(_vm.slideshop_status_id),callback:function ($$v) {_vm.slideshop_status_id=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"slideshop_status_id"}}),(_vm.slideshop_status_id == 3)?_c('v-datetime-picker',{attrs:{"rules":[_vm.required],"ok-text":_vm.$t('save'),"clear-text":_vm.$t('cancel'),"text-field-props":{
            dense: true,
            outlined: true,
            flat:true
          },"date-picker-props":{
            locale: _vm.$i18n.locale,
            elevation:0
          },"time-picker-props":{
            locale: _vm.$i18n.locale,
            elevation:0,
            format:'24hr'
          },"label":_vm.$t('start_showing')},model:{value:(_vm.slideshop_start),callback:function ($$v) {_vm.slideshop_start=$$v},expression:"slideshop_start"}},[_c('template',{slot:"dateIcon"},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCalendarToday))])],1),_c('template',{slot:"timeIcon"},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClockOutline))])],1)],2):_vm._e(),(_vm.slideshop_status_id == 3)?_c('v-datetime-picker',{attrs:{"ok-text":_vm.$t('save'),"rules":[_vm.required],"clear-text":_vm.$t('cancel'),"text-field-props":{
            dense: true,
            outlined: true,
          },"date-picker-props":{
            locale: _vm.$i18n.locale,
          },"time-picker-props":{
            locale: _vm.$i18n.locale,
            format:'24hr'
          },"label":_vm.$t('end_of_show')},model:{value:(_vm.slideshop_end),callback:function ($$v) {_vm.slideshop_end=$$v},expression:"slideshop_end"}},[_c('template',{slot:"dateIcon"},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCalendarToday))])],1),_c('template',{slot:"timeIcon"},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClockOutline))])],1)],2):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.loading,"disabled":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]),_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.$emit('update:is-add-new-slide-show',false)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }