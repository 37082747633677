var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('Slide Show'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.dataTableList,"options":_vm.options,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.slideshop_page_id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.slideId = item.slideshop_page_id;_vm.isShowDetail = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFormatListBulleted))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("list")))])])]}}],null,true)})],1),_c('DetailSlideShow',{attrs:{"id":String(_vm.slideId)},model:{value:(_vm.isShowDetail),callback:function ($$v) {_vm.isShowDetail=$$v},expression:"isShowDetail"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }