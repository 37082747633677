<template>
  <div>
    <v-dialog
      v-model="isShowDetail"
      persistent
      max-width="1000"
    >
      <v-card>
        <v-card-title class="px-2">
          {{ $t('ManageSlideShow') }}
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="me-2 d-none d-md-block"
            @click="isAddNewSlideShow = true"
          >
            <v-icon class="me-1">
              {{ icons.mdiPlus }}
            </v-icon> {{ $t('add_slideshows') }}
          </v-btn>
          <v-btn
            color="primary"
            class="me-2 d-block d-md-none"
            fab
            outlined
            icon
            @click="isAddNewSlideShow = true"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
          <v-btn
            color="info"
            outlined
            class="d-none d-md-block"
            @click="isSortSlideShow = true"
          >
            <v-icon class="me-1">
              {{ icons.mdiSort }}
            </v-icon>  {{ $t('sort') }}
          </v-btn>
          <v-btn
            color="info"
            outlined
            class="d-block d-md-none"
            fab
            icon
            @click="isSortSlideShow = true"
          >
            <v-icon class="me-1">
              {{ icons.mdiSort }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          :headers="columns"
          :items="dataTableList"
          :options.sync="options"
          :loading="loading"
          mobile-breakpoint="0"
          disable-sort
          hide-default-footer
          :loading-text="$t('data_loading')"
          :no-data-text="$t('no_information')"
        >
          <template v-slot:[`item.slideshop_id`]="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:[`item.slideshop_image`]="{ item }">
            <v-img
              width="100px"
              class="rounded-lg elevation-2 my-2"
              :src="item.slideshop_image"
            ></v-img>
          </template>
          <template v-slot:[`item.slideshop_image_half`]="{ item }">
            <v-img
              width="80px"
              class="rounded-lg elevation-2 my-2"
              :src="item.slideshop_image_half"
            ></v-img>
          </template>
          <template v-slot:[`item.slideshop_name`]="{ item }">
            {{ item.slideshop_name }} <br />
            <a
              :href="`${item.slideshop_link}`"
              :target="item.slideshop_open_link == '2' ? '_blank' : ''"
            >
              {{ item.slideshop_link }}
            </a>
          </template>
          <template v-slot:[`item.slideshop_status_id`]="{ item }">
            {{
              item.slideshop_status_id == 1
                ? $t("open")
                : item.slideshop_status_id == 2
                  ? $t("close")
                  : $t("set_time")
            }}
          </template>
          <template v-slot:[`item.slideshop_create`]="{ item }">
            <ConverseDate :date="item.slideshop_create" />
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip
              color="#212121"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  @click="dataEdit = item;isEditSlideShow = true"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("edit") }}</span>
            </v-tooltip>
            <v-tooltip
              color="#212121"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  @click="idDelete =item.slideshop_id;isDelete =true"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("delete") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:is-show-detail',false)"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AddNewSlideShow
      :id="id"
      v-model="isAddNewSlideShow"
      @onAdd="getSlideManagementList"
    />
    <SortSlideShow
      v-model="isSortSlideShow"
      :data-list="dataTableList"
      @onUpdate="getSlideManagementList"
    />
    <EditSlideShow
      v-model="isEditSlideShow"
      :data-edit="dataEdit"
      @onUpdate="getSlideManagementList"
    />

    <v-dialog
      v-model="isDelete"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            class="me-1"
            color="error"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon>
          {{ $t('confirmDeleteSlideShow') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="loadingRemove"
            :disabled="loadingRemove"
            @click="confirmDelete"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isDelete = false"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import {
  mdiPlus, mdiSort, mdiDeleteOutline, mdiPencilOutline, mdiAlertOutline,
} from '@mdi/js'
import slideManagement from '@/api/WebsiteManageMent/slideManagement'
import { i18n } from '@/plugins/i18n'
import AddNewSlideShow from './AddNewSlideShow.vue'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import SortSlideShow from './SortSlideShow.vue'
import EditSlideShow from './EditSlideShow.vue'
import store from '@/store'

export default {
  components: {
    AddNewSlideShow,
    ConverseDate,
    SortSlideShow,
    EditSlideShow,
  },
  model: {
    prop: 'isShowDetail',
    event: 'update:is-show-detail',
  },
  props: {
    isShowDetail: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const dataTableList = ref([])
    const columns = ref([
      {
        text: '#',
        align: 'start',
        value: 'slideshop_id',
      },
      { text: i18n.t('full_picture'), value: 'slideshop_image', width: 120 },
      { text: i18n.t('half_picture'), value: 'slideshop_image_half', width: 120 },
      { text: i18n.t('name'), value: 'slideshop_name', width: 120 },
      { text: i18n.t('status'), value: 'slideshop_status_id', width: 120 },
      { text: i18n.t('created_on'), value: 'slideshop_create', width: 120 },
      { text: i18n.t('option'), value: 'actions', width: 120 },
    ])
    const options = ref({})
    const loading = ref(false)
    const isAddNewSlideShow = ref(false)
    const isSortSlideShow = ref(false)

    const idDelete = ref('')
    const isDelete = ref(false)
    const loadingRemove = ref(false)

    const isEditSlideShow = ref(false)
    const dataEdit = ref({})

    const { slideManagementDetailList, slideManagementRemove } = slideManagement

    const getSlideManagementList = () => {
      loading.value = true
      slideManagementDetailList({ slideshop_page_id: props.id }).then(res => {
        dataTableList.value = res
        loading.value = false
      })
    }

    const confirmDelete = () => {
      loadingRemove.value = true
      slideManagementRemove(idDelete.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        getSlideManagementList()
        isDelete.value = false
        loadingRemove.value = false
      })
    }
    watch(() => props.isShowDetail, val => {
      if (val) {
        getSlideManagementList()
      }
    })

    return {
      dataTableList,
      columns,
      options,
      loading,
      isAddNewSlideShow,
      isSortSlideShow,
      loadingRemove,
      isEditSlideShow,
      dataEdit,
      isDelete,
      idDelete,
      confirmDelete,
      getSlideManagementList,
      icons: {
        mdiPlus, mdiSort, mdiDeleteOutline, mdiPencilOutline, mdiAlertOutline,

      },
    }
  },
}
</script>
