<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t('Slide Show') }}
      </v-card-title>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.slideshop_page_id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="slideId = item.slideshop_page_id;isShowDetail = true"
              >
                <v-icon>{{ icons.mdiFormatListBulleted }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("list") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <DetailSlideShow
      :id="String(slideId)"
      v-model="isShowDetail"
    />
  </div>
</template>

<script>
import { mdiFormatListBulleted } from '@mdi/js'
import useSlideManagement from './useSlideManagement'
import DetailSlideShow from './DetailSlideShow.vue'

export default {
  components: {
    DetailSlideShow,
  },
  setup() {
    const {
      dataTableList,
      columns,
      options,
      loading,
      isShowDetail,
      slideId,
    } = useSlideManagement()

    return {
      dataTableList,
      columns,
      options,
      loading,
      isShowDetail,
      slideId,
      icons: {
        mdiFormatListBulleted,
      },
    }
  },

}
</script>
