import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const slideManagementList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'slideshop/list',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.log('get slide show list err : ', err)

      return []
    })

  return response
}
const slideManagementDetailList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'slideshop/lists',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.log('get detail slide show list err : ', err)

      return []
    })

  return response
}

const slideManagementAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'slideshop',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('add slide show err : ', err)

      return {}
    })

  return response
}
const slideManagementSort = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'slideshop/sort',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('sort slide show err : ', err)

      return {}
    })

  return response
}

const slideManagementUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'slideshop/edit',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('edit slide show err : ', err)

      return {}
    })

  return response
}

const slideManagementRemove = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'slideshop',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('delete slide show err : ', err)

      return {}
    })

  return response
}
export default {
  slideManagementList, slideManagementDetailList, slideManagementAdd, slideManagementSort, slideManagementUpdate, slideManagementRemove,
}
