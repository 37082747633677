<template>
  <v-dialog
    v-model="isSortSlideShow"
    persistent
    max-width="400"
  >
    <v-card>
      <v-card-title>
        {{ $t('sort') }}
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item-group>
            <draggable
              v-model="dataListLocal"
              handle=".handle"
              @end="updateSort"
            >
              <template v-for="(item, index) in dataListLocal">
                <v-list-item :key="index">
                  <v-list-item-icon
                    class="handle"
                    style="cursor:all-scroll"
                  >
                    <v-icon>{{ icons.mdiMenu }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.slideshop_name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </draggable>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          outlined
          @click="$emit('update:is-sort-slide-show',false)"
        >
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiMenu } from '@mdi/js'
import draggable from 'vuedraggable'
import slideManagement from '@/api/WebsiteManageMent/slideManagement'
import store from '@/store'

export default {
  components: {
    draggable,
  },
  model: {
    prop: 'isSortSlideShow',
    event: 'update:is-sort-slide-show',
  },
  props: {
    isSortSlideShow: {
      type: Boolean,
      default: false,
    },
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { slideManagementSort } = slideManagement
    const dataListLocal = ref([])

    const updateSort = () => {
      const dataSort = []
      dataListLocal.value.forEach(element => {
        dataSort.push(element.slideshop_id)
      })
      slideManagementSort({ slideshop_id: dataSort }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('onUpdate')
      })
    }

    watch(() => props.isSortSlideShow, value => {
      if (value) {
        dataListLocal.value = JSON.parse(JSON.stringify(props.dataList))
      }
    })

    return {
      updateSort,
      dataListLocal,
      icons: {
        mdiMenu,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
