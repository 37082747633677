import { onMounted, ref } from '@vue/composition-api'
import slideManagement from '@/api/WebsiteManageMent/slideManagement'
import { i18n } from '@/plugins/i18n'

export default function useSlideManagement() {
  const dataTableList = ref([])
  const columns = ref([
    {
      text: '#',
      align: 'start',
      value: 'slideshop_page_id',
    },
    {
      text: i18n.t('slideshow_page'),
      value: 'slideshop_page_name',
    },
    {
      text: i18n.t('option'),
      value: 'actions',
    },
  ])
  const options = ref({})
  const loading = ref(false)
  const isShowDetail = ref(false)
  const slideId = ref('')

  const { slideManagementList } = slideManagement

  onMounted(() => {
    getSlideManagementList()
  })

  const getSlideManagementList = () => {
    slideManagementList({ lang: i18n.locale }).then(res => {
      dataTableList.value = res
    })
  }

  return {
    dataTableList,
    columns,
    options,
    loading,
    isShowDetail,
    slideId,
  }
}
